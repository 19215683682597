import { useEffect } from 'react';
import Demo from './components/demo';
import Product from './components/product';
import TopNav from './components/navBar';
import NotFound from './components/notFound';
import Footer from './components/footer';
import HomeContent from './components/home';
import Terms from './components/terms';
import Licenses from './components/licenses';
import MarketplaceRedirect from './components/marketplaceRedirect';
import EBayOAuth from './components/ebayLogin';
import AuthWrapper from './components/authWrapper';
import FrameTest from './components/frameTest';
import Dashboard from './components/dashboard';
import DashboardLayout from './components/dashboardLayout';
import Settings from './components/settings';
import CustomerSelect from './components/customerSelect';
import ProductPricingTable from './components/productPricingTable';
import StripePricingTable from './components/stripePricingTable';
import Uploader from './components/uploader';
import ProductList from './components/productList';
import Login from './components/login';
import Team from './components/team';
import Blog from './components/blog';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import ContactForm from './components/contact';

import './shopik.webapp.default.css';
import './firebase';

// Import Auth components
import { AuthProvider } from './components/authContext'; // New centralized AuthContext
import  PrivateRoute from './components/privateRoute'; // A PrivateRoute wrapper
import UserManagement from './components/userManagement';
import ProductEditGeneric from './components/productEditGeneric';

// Router setup
const router = createBrowserRouter([
  {
    path: '/',
    element: (
      <>
        <TopNav />
        <HomeContent />
        <Footer />
      </>
    ),
    errorElement: (
      <>
        <TopNav />
        <NotFound />
        <Footer />
      </>
    ),
  },
  {
    path: 'terms',
    element: (
      <>
        <Terms />      
      </>
    ),
  },
  {
    path: 'licenses',
    element: (
      <>
        <TopNav />
        <Licenses />
        <Footer />
      </>
    ),
  },
  {
    path: 'team',
    element: (
      <>
        <TopNav />
        <Team />
        <Footer />
      </>
    ),
  },
  {
    path: 'tryitnow',
    element: (
      <>
        <TopNav />
        <Demo />
        <Footer />
      </>
    ),
  },
  {
    path: 'contact',
    element: (
      <>
        <ContactForm />
      </>
    ),
  },
  {
    path: 'products/:customer',
    element:  <PrivateRoute>
    <DashboardLayout>
    <ProductList />
    </DashboardLayout>
    </PrivateRoute>,
  },
  {
    path: 'marketplace',
    element: <MarketplaceRedirect />,
  },
  {
    path: 'm',
    element: <MarketplaceRedirect />,
  },
  {
    path: 'products/:customer/:product',
    element:  <PrivateRoute>
    <DashboardLayout>
      <Product/>
    </DashboardLayout>
    </PrivateRoute>,
  },
  {
    path: 'm/:customer/:product',
    element:  <PrivateRoute>
    <DashboardLayout>
      <Product />
    </DashboardLayout>
    </PrivateRoute>,
  },
  {
    path: 'shopifybulkuploader/:customer',
    element: (<><AuthWrapper useBulkUploader={true} /></>),
  },
  {
    path: 'casestudies/:customer',
    element: <FrameTest />,
  },
  {
    path: 'blog/:slug',
    element: <Blog />,
  },
  {
    path: 'blog/',
    element: <Blog />,
  },
  {
    path: 'uploader',
    element: (
      <PrivateRoute>
        <DashboardLayout>
          <Uploader />
        </DashboardLayout>
      </PrivateRoute>
    ),
  },
  {
    path: 'dashboard',
    element: (
      <PrivateRoute>
        <DashboardLayout>
          <Dashboard />
        </DashboardLayout>
      </PrivateRoute>
    ),
  },
  {
    path: 'user-management',
    element: (
      <PrivateRoute>
        <DashboardLayout>
          <UserManagement />
        </DashboardLayout>
      </PrivateRoute>
    ),
  },
  {
    path: 'settings',
    element: (
      <PrivateRoute>
        <DashboardLayout>
          <Settings />
        </DashboardLayout>
      </PrivateRoute>
    ),
  },{
    path: 'plans',
    element: (
      <PrivateRoute>
        <DashboardLayout>
          <StripePricingTable />
        </DashboardLayout>
      </PrivateRoute>
    ),
  },
  {
    path: 'pricing/:customer/:document',
    element: (
      <ProductPricingTable  carPartList={true} />
    ),
  },
  {
    path: 'product-pricing/:customer/:document',
    element: (
      <ProductPricingTable  carPartList={false} />
    ),
  },
  {
    path: 'login',
    element:(
          <Login />
    ),
  },
  {
    path: 'customer-select',
    element:(
      <PrivateRoute>
      <CustomerSelect/>
    </PrivateRoute>
    ),
  },
  {
    path: 'ebayLogin/:customer/:destination',
    element: <EBayOAuth />,
  },
  {
    path: 'ebayLogin',
    element: <EBayOAuth />,
  },
]);

function App() {
  useEffect(() => {
    window.Webflow && window.Webflow.destroy();
    window.Webflow && window.Webflow.ready();
    window.Webflow && window.Webflow.require('ix2').init();
    document.dispatchEvent(new Event('readystatechange'));
  });

  useEffect(() => {
    // Define the event listener callback function
    const handleMessage = (event) => {
      if (event.data) {
        const styles = event.data;
        Object.keys(styles).forEach((variable) => {
          document.documentElement.style.setProperty(variable, styles[variable]);
        });
      }
    };
  
    // Add the event listener
    window.addEventListener('message', handleMessage);
  
    // Cleanup function to remove the event listener
    return () => {
      window.removeEventListener('message', handleMessage);
    };
  }, []);

  return (
    <AuthProvider>
      <div className="App">
        <RouterProvider router={router} />
      </div>
    </AuthProvider>
  );
}

export default App;