import React, { useState } from "react";
import { Box, Container, TextField, Button, Typography, ToggleButton, ToggleButtonGroup } from "@mui/material";
import TopNav from "./navBar";
import Footer from "./footer";
import { logEvent } from "firebase/analytics";
import { analytics } from "../firebase";

const ContactForm = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    subject: "",
    message: "",
    contactType: "enterprise",
  });

  const [errors, setErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [success, setSuccess] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleContactTypeChange = (event, newContactType) => {
    if (newContactType) {
      setFormData({ ...formData, contactType: newContactType });
    }
  };

  const validate = () => {
    const newErrors = {};
    if (!formData.name) newErrors.name = "Name is required";
    if (!formData.email) {
      newErrors.email = "Email is required";
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      newErrors.email = "Invalid email address";
    }
    if (!formData.subject) newErrors.subject = "Subject is required";
    if (!formData.message) newErrors.message = "Message is required";
    return newErrors;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const validationErrors = validate();
    setErrors(validationErrors);

    if (Object.keys(validationErrors).length === 0) {
      setIsSubmitting(true);

      try {
        const url = process.env.REACT_APP_API_URL + "web-contact-support-form";
        const response = await fetch(url, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(formData),
        });
        if (formData.contactType === "personal") {
          logEvent(analytics, "contact_form_submission_personal", {
            name: formData.name,
            email: formData.email,
            subject: formData.subject,
            contactType: formData.contactType,
          });
        } else {
          logEvent(analytics, "contact_form_submission_enterprise", {
            name: formData.name,
            email: formData.email,
            subject: formData.subject,
            contactType: formData.contactType,
          });
        }
        if (response.ok) {
          setSuccess(true);
          setFormData({
            name: "",
            email: "",
            subject: "",
            message: "",
            contactType: "enterprise",
          });
        } else {
          console.error("Failed to submit form");
        }
      } catch (error) {
        console.error("Error submitting the form:", error);
      } finally {
        setIsSubmitting(false);
      }
    }
  };

  return (
    <Box
      sx={{
        backgroundColor: "#160b2d",
        minHeight: "100vh",
        paddingTop: 4,
        paddingBottom: 4,
      }}
    >
      <Box>
        <Box component="header" sx={{ marginBottom: 8 }}>
          <TopNav />
        </Box>

        <Box
          component="main"
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "flex-start",
            marginBottom: 4,
            px: { xs: 2, sm: 4 },
          }}
        >
          <Container
            maxWidth="md"
            sx={{
              backgroundColor: "white",
              borderRadius: 2,
              boxShadow: 3,
              padding: 4,
            }}
          >
            <Box sx={{ textAlign: "left", width: "100%" }}>
              <Typography variant="h4" gutterBottom>
                Contact Us
              </Typography>
              {success && (
                <Typography
                  variant="body1"
                  color="success.main"
                  sx={{ marginBottom: 2 }}
                >
                  Thank you for your message! We'll get back to you soon.
                </Typography>
              )}
              <form onSubmit={handleSubmit}>
              <Box
              sx={{
                display: "flex", // Flexbox to place elements in a row
                flexDirection: "row", // Explicit horizontal alignment
                alignItems: "center", // Center label and buttons vertically
                marginBottom: 2, // Space below this group
              }}
            >
              <ToggleButtonGroup
                color="primary"
                value={formData.contactType}
                exclusive
                onChange={handleContactTypeChange}
                sx={{
                  display: "inline-flex", // Ensure buttons stay inline
                  flexWrap: "nowrap", // Prevent buttons from wrapping
                }}
              >
                <Box  sx={{
                display: "flex", // Flexbox to place elements in a row
                flexDirection: "row", // Explicit horizontal alignment
                alignItems: "center", // Center label and buttons vertically
                
              }}>
                <ToggleButton
                  value="personal"
                  sx={{
                    textTransform: "none", // Keep text case as-is
                    padding: "6px 16px", // Padding for uniform button size
                    minWidth: "80px", // Ensure consistent width
                  }}
                >
                  Personal
                </ToggleButton>
                <ToggleButton
                  value="enterprise"
                  sx={{
                    textTransform: "none",
                    padding: "6px 16px",
                    minWidth: "80px", // Ensure consistent width
                  }}
                >
                  Enterprise
                </ToggleButton>
                </Box>
              </ToggleButtonGroup>
            </Box>
                <TextField
                  label="Name"
                  name="name"
                  fullWidth
                  margin="normal"
                  value={formData.name}
                  onChange={handleChange}
                  error={!!errors.name}
                  helperText={errors.name}
                InputProps={{
                    sx: {
                      paddingLeft: '10px',  // Add 10px of padding to the left
                    },
                  }}
                />
                <TextField
                  label="Email"
                  name="email"
                  fullWidth
                  margin="normal"
                  value={formData.email}
                  onChange={handleChange}
                  error={!!errors.email}
                  helperText={errors.email}
                InputProps={{
                    sx: {
                      paddingLeft: '10px',  // Add 10px of padding to the left
                    },
                  }}
                />
                <TextField
                  label="Subject"
                  name="subject"
                  fullWidth
                  margin="normal"
                  value={formData.subject}
                  onChange={handleChange}
                  error={!!errors.subject}
                  helperText={errors.subject}
                InputProps={{
                    sx: {
                      paddingLeft: '10px',  // Add 10px of padding to the left
                    },
                  }}
                />
                <TextField
                  label="Message"
                  name="message"
                  fullWidth
                  multiline
                  rows={4}
                  margin="normal"
                  value={formData.message}
                  onChange={handleChange}
                  error={!!errors.message}
                  helperText={errors.message}
                />
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  fullWidth
                  sx={{ marginTop: 2 }}
                  disabled={isSubmitting}
                >
                  {isSubmitting ? "Sending..." : "Submit"}
                </Button>
              </form>
            </Box>
          </Container>
        </Box>

        <Box component="footer" sx={{ mt: "auto", width: "100%" }}>
          <Footer />
        </Box>
      </Box>
    </Box>
  );
};

export default ContactForm;