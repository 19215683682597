import { db } from './firebase.js'
import { query, collection, collectionGroup, orderBy, getDocs, limit, startAfter, startAt, doc , where, or, and,  getCountFromServer } from "firebase/firestore";


export const getProducts = async (passedLimit, lastSnap, customer, searchString, completion, override = false) => {
  try {
    let productsQuery;

    // Build query based on provided filters
    if (customer) {
      if (searchString) {
        productsQuery = searchCustomerProducts(
          passedLimit,
          lastSnap,
          customer,
          searchString.toLowerCase(),
          override
        );
      } else {
        productsQuery = query(
          collection(db, `customers/${customer}/products`),
          orderBy("created", "desc"),
          ...(lastSnap ? [startAfter(lastSnap)] : []),
          limit(passedLimit)
        );
      }
    } else {
      if (searchString) {
        productsQuery = searchProducts(
          passedLimit,
          lastSnap,
          searchString.toLowerCase(),
          override
        );
      } else {
        productsQuery = query(
          collection(db, "products"),
          orderBy("created", "desc"),
          ...(lastSnap ? [startAfter(lastSnap)] : []),
          limit(passedLimit)
        );
      }
    }

    // Execute the query and process results
    const querySnapshot = await getDocs(productsQuery);
    const productList = [];
    const currentId = window.location.href.substring(window.location.href.lastIndexOf("/") + 1);

    querySnapshot.forEach((doc) => {
      const tempData = doc.data();
      const results = tempData.editedResults || tempData.chatGptResponse || {};
      results["noBgImageUrls"] = tempData.noBgImageUrls || [];
      results["thumbnailUrls"] = [];
      results["productUri"] = doc.id;

      // Handle price formatting
      if (tempData.isFree) {
        results["price"] = "FREE";
      } else if (typeof results.price === "string") {
        results["price"] = "$" + results.price.replace("$", "");
      } else {
        results["price"] = "Unknown";
      }

      // Set main image and thumbnail URLs
      if (tempData.mainImages) {
        tempData.mainImages.forEach((image) => {
          if (!image.isHidden) {
            results["thumbnailUrls"].push(image.urlString);
          }
        });
        if (results["thumbnailUrls"].length > 0) {
          results["mainImage"] = results["thumbnailUrls"][0];
        }
      } else {
        if (tempData.noBgImageUrls?.length > 0) {
          results["mainImage"] = tempData.noBgImageUrls[0];
        }
        results["thumbnailUrls"] = tempData.thumbnailUrls || [];
      }

      // Additional metadata
      results.created = tempData.created || null;
      results.onEbay = !!tempData.eBayArray;
      results.onShopify = !!tempData.shopifyArray;

      // Consolidated data overrides
      if (tempData.consolidatedData) {
        if (tempData.consolidatedData["Title"]) {
          results["title"] = tempData.consolidatedData["Title"];
        }
        if (tempData.consolidatedData["Description"]) {
          results["description"] = tempData.consolidatedData["Description"];
        }
        if (tempData.consolidatedData["Price"]) {
          results["price"] = "$" + tempData.consolidatedData["Price"].replace("$", "");
        }
      }

      // Filter out hidden or excluded products
      if (
        currentId !== doc.id &&
        (tempData.hideFromMarketplace == null || tempData.hideFromMarketplace === false || customer != null || override) &&
        (tempData.customerId !== "siteDemo" || customer != null)
      ) {
        productList.push(results);
      }
    });

    // Get the last document for pagination
    const lastItem = querySnapshot.docs[querySnapshot.docs.length - 1] || null;
    completion(productList, lastItem); // Pass product list and cursor to completion callback
  } catch (error) {
    console.error("Error fetching products: ", error);
    completion([], null); // Handle error gracefully by passing empty results
  }
};

export const getProductCount = async (customer) => {
  try {
    const custColRef = collection(db, "customers");
    const custDocRef = doc(custColRef, customer);
    const prodColRef = collection(custDocRef, "products");

    // Use Firestore's count aggregation
    const snapshot = await getCountFromServer(query(prodColRef));
    return snapshot.data().count; // Retrieve the count value
  } catch (error) {
    console.error("Error fetching product count:", error);
    throw error;
  }
};
const fetchProducts = (passedLimit, lastSnap, override=false) => {
  const customerFilter =  or(where('customerId', '==', 'A77eZv8Ku1h5Ohn68cFarpjNrkk1'),
  where('customerId', '==', 'ntjs7Ybyd1XibATjUN3xVFCDQsa2'))
  const filter = (db.app.options.projectId == 'shopik-stage' || override) ? where('finishedProcessing', '==', true) :  and(where('finishedProcessing', '==', true), where('hideFromMarketplace', '==', false), customerFilter)
  return lastSnap
    ? query(
        collectionGroup(db, 'products'),
        filter,
        orderBy('created', 'desc'),
        startAfter(lastSnap),
        limit(passedLimit)
      )
    : query(collectionGroup(db, 'products'), 
        filter,
        orderBy('created', 'desc'), 
        limit(passedLimit));
};

export const fetchCustomerProducts = (limitCount, customer, lastSnap, override) => {
  const prodColRef = collection(db, `customers/${customer}/products`);
  const filter = where('finishedProcessing', '==', true)
  if (lastSnap) {
    return query(prodColRef, filter, orderBy("created"), startAfter(lastSnap), limit(limitCount));
  }
  return query(prodColRef, filter, orderBy("created"), limit(limitCount));
};


// const fetchCustomerProducts = (passedLimit, customer, lastSnap, override=false) => {
//   const custColRef = collection(db, "customers")
//   const custDocRef = doc(custColRef, customer)
//   const filter = where('finishedProcessing', '==', true)
  
//   return lastSnap
//     ? query(
//         collection(custDocRef, 'products'),
//         filter,
//         orderBy('created', 'desc'),
//         startAfter(lastSnap),
//         limit(passedLimit)
//       )
//     : query(collection(custDocRef, 'products'),
//         filter,
//         orderBy('created', 'desc'), 
//         limit(passedLimit));
// };

const searchProducts = (passedLimit, lastSnap, searchString, override=false) => {
  const filter = (db.app.options.projectId == 'shopik-stage' || override) ? and(where('finishedProcessing', '==', true), where('searchableText', 'array-contains', searchString)) : and(where('finishedProcessing', '==', true), where('hideFromMarketplace', '==', false), where('searchableText', 'array-contains', searchString))
  return lastSnap
    ? query(
        collectionGroup(db, 'products'),
        filter,
        orderBy('created', 'desc'),
        startAfter(lastSnap),
        limit(passedLimit)
      )
    : query(collectionGroup(db, 'products'), 
        filter,
        orderBy('created', 'desc'), 
        limit(passedLimit));
};

const searchCustomerProducts = (passedLimit, lastSnap, customer, searchString, override=false) => {
  const custColRef = collection(db, "customers")
  const custDocRef = doc(custColRef, customer)

  return lastSnap
    ? query(
        collection(custDocRef, 'products'),
        where('finishedProcessing', '==', true),
        where('searchableText', 'array-contains', searchString),
        where('hideFromMarketplace', '==', false),
        orderBy('created', 'desc'),
        startAfter(lastSnap),
        limit(passedLimit)
      )
    : query(
        collection(custDocRef, 'products'),
        where('finishedProcessing', '==', true),
        where('hideFromMarketplace', '==', false),
        where('searchableText', 'array-contains', searchString),
        orderBy('created', 'desc'), 
        limit(passedLimit));
};