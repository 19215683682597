import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Typography, CircularProgress, Pagination, Box, Checkbox } from "@mui/material";
import { getProducts, getProductCount } from "../databaseManager";
import AdaptiveImage from "./adaptiveImage";
import { formattedDate } from "./sharedUtils";

export default function ProductList() {
  const navigate = useNavigate();
  const params = useParams();
  const customer = params["customer"];

  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);
  const [pageSize] = useState(10);
  const [lastSnap, setLastSnap] = useState(null);
  const [loading, setLoading] = useState(false);
  const [totalItems, setTotalItems] = useState(0);
  const [cursors, setCursors] = useState([]); // Store cursors for each page


  // Fetch total product count
  useEffect(() => {
    const fetchProductCount = async () => {
      try {
        const count = await getProductCount(customer);
        setTotalItems(count);
      } catch (error) {
        console.error("Failed to fetch product count:", error);
      }
    };

    fetchProductCount();
  }, [customer]);

  useEffect(() => {
    setLoading(true);
  
    getProducts(pageSize, lastSnap, customer, '', (productList, lastVisible) => {
      setData(productList);
      setLastSnap(lastVisible);
  
      // Store the cursor for the current page
      setCursors((prevCursors) => {
        const updatedCursors = [...prevCursors];
        updatedCursors[page - 1] = lastVisible;
        return updatedCursors;
      });
  
      setLoading(false);
    }, false);
  }, [page, customer]);


  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  
    if (newPage === 1) {
      // Reset to the first page
      setLastSnap(null);
    } else if (cursors[newPage - 1]) {
      // Use the stored cursor for the selected page
      setLastSnap(cursors[newPage - 1]);
    }
  };

  return (
    <Box sx={{ padding: 2 }}>
      <Typography variant="h4" component="h2" gutterBottom>
        Product List
      </Typography>
      {loading ? (
        <CircularProgress />
      ) : (
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Image</TableCell>
                <TableCell>Title</TableCell>
                <TableCell>Price</TableCell>
                <TableCell>Date Created</TableCell>
                <TableCell>On eBay</TableCell>
                <TableCell>On Shopify</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data.map((product, index) => (
                <TableRow
                  key={`${product.created}-${index}`}
                  hover
                  sx={{ cursor: "pointer" }}
                  onClick={() => navigate(product.productUri)}
                >
                  <TableCell sx={{ height: 100, width: 100 }}>
                    <AdaptiveImage src={product.mainImage} style={{ width: "80px", height: "80px", objectFit: "cover" }} />
                  </TableCell>
                  <TableCell>{product.title}</TableCell>
                  <TableCell>{product.price}</TableCell>
                  <TableCell>{formattedDate(product.created)}</TableCell>
                  <TableCell>
                    <Checkbox checked={product.onEbay} disabled />
                  </TableCell>
                  <TableCell>
                    <Checkbox checked={product.onShopify} disabled />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
      <Box sx={{ display: "flex", justifyContent: "center", marginTop: 2 }}>
        <Pagination
          count={Math.ceil(totalItems / pageSize)}
          page={page}
          onChange={handlePageChange}
          color="primary"
        />
      </Box>
    </Box>
  );
}