import React from 'react';
import TopNav from './navBar';
import Footer from './footer';
import { Container, Typography, Box, Divider, Card, CardContent } from '@mui/material';

// Automatically gets the current date in the format "Month Day, Year"
const currentDate = new Date().toLocaleDateString('en-US', {
  year: 'numeric',
  month: 'long',
  day: 'numeric',
});

export default function Terms() {
  return (
    <Box   sx={{ 
      backgroundColor: '#160b2d',  // Light gray background for the entire page
      minHeight: '100vh',          // Full viewport height
      paddingTop: 4,
      paddingBottom: 4 
    }}> 
   
   <Box>
        <Box component="header" sx={{ marginBottom: 4 }}>
          <TopNav />
        </Box>
        
        <Box 
        component="main" 
        sx={{ 
          flexGrow: 1, 
          paddingTop: 6, // Adjust to the height of your TopNav
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'flex-start',
          marginBottom: 4,
          px: { xs: 2, sm: 4 }, // Adds horizontal padding on small screens
        }}
      >
      <Container maxWidth="md">
        <Card sx={{ boxShadow: 3, borderRadius: 2 }}>
          <CardContent>
            <Typography variant="h3" gutterBottom>
              Terms & Conditions
            </Typography>
            <Typography variant="subtitle1" color="textSecondary" gutterBottom>
              Current as of {currentDate}.
            </Typography>
            <Divider sx={{ marginBottom: 3 }} />

            <Box>
              <Typography variant="h4" gutterBottom>
                Website Terms of Use
              </Typography>
              <Typography variant="body1" paragraph>
                Welcome to www.shopik.io (together with any related websites, the “Site”). The Site is owned and operated by Shopik, Inc. (“Shopik”). Please read these Terms of Use (“Terms”) carefully before using the Site. By accessing or using the Site or any of the content on the Site you agree to be legally bound by these Terms. If you do not accept these Terms, do not use the Site or any of its Content (defined below).
              </Typography>
              <Typography variant="body1" paragraph>
                You represent to Shopik that you are lawfully able to enter into contracts (e.g., you are not a minor). If you are entering into these Terms for an entity, such as the company you work for, you represent that you have authority to bind that entity and you agree that “you” as used in these Terms includes both you personally and the entity you represent. You and Shopik are collectively referred to as the “Parties” and each is a “Party”.
              </Typography>
              <Divider sx={{ marginY: 3 }} />

              <Typography variant="h5" gutterBottom>
              Master Subscription Agreement
              </Typography>
              <Typography variant="body1" paragraph>
              These Terms do not govern the use of the hosted application and infrastructure monitoring service as well as other services (the “Service”) operated by Shopik. If you are accessing or using the Service – even if you are doing so through a free trial – then you are subject to Shopik's Master Subscription Agreement or such other written contract as may be separately agreed and signed between you and Shopik.
              </Typography>
              <Divider sx={{ marginY: 3 }} />
              <Typography variant="h5" gutterBottom>
                1. Shopik Content
              </Typography>
              <Typography variant="body1" paragraph>
                The Site contains HTML, applications, messages, text, files, images, photos, video, sounds, profiles, works of authorship, and other content (collectively, “Content”) of Shopik or its licensors (“Shopik Content”). The Site (including the Shopik Content) is protected by copyright, trademark, trade secret, and other laws; and as between you and Shopik, Shopik owns and retains all rights in the Site and the Shopik Content. 
              </Typography>
              <Typography variant="h5" gutterBottom>
                2. Trademarks
              </Typography>
              <Typography variant="body1" paragraph>
                The trademarks, logos, and service marks (“Marks”) displayed on the Site are the property of Shopik or other third parties. You are not permitted to use these Marks without the prior written consent of Shopik or such third party.
              </Typography>
              <Typography variant="h5" gutterBottom>
                3. Third-Party Services
              </Typography>
              <Typography variant="body1" paragraph>
              The Site may make available, or third parties may provide, links to other websites, applications, resources, advertisements, Content, or other products or services created, hosted, or made available by third parties (“Third-Party Services”), and such third parties may use other third parties to provide portions of the Third-Party Service to you, such as technology, development, or payment services. When you access or use a Third-Party Service, you are interacting with the applicable third party, not with Shopik, and you do so at your own risk. Shopik is not responsible for, and makes no warranties, express or implied, as to, the Third-Party Services or the providers of such Third-Party Services (including without limitation the accuracy or completeness of the information provided by such Third-Party Service or the privacy practices of any third party). Inclusion of any Third-Party Service or a link thereto on the Site does not imply approval or endorsement of such Third-Party Service. Shopik is not responsible or liable for the Content or practices of any Third-Party Service or third party, even if such Third-Party Service links to, or is linked by, the Site.
              </Typography>
              <Typography variant="h5" gutterBottom>
                4. Privacy
              </Typography>
              <Typography variant="body1" paragraph>
              Please review Shopik's Privacy Policy for the Site (“Privacy Policy”), to learn about Shopik's information collection, usage, and disclosures practices with respect to information collected by Shopik through the Site.
              </Typography>
              <Typography variant="h5" gutterBottom>
                5. Your Content
              </Typography>
              <Typography variant="body1" paragraph>
              By posting, displaying, publishing, or making available for download or use any Content on the Site (other than personal information that is subject to the Privacy Policy), you hereby grant Shopik a perpetual, worldwide, nonexclusive, irrevocable, royalty-free, sublicensable (through multiple tiers) license to perform, display, reproduce, prepare derivative works from, distribute, sell, sublicense, transfer, and otherwise use without restriction all or any part of such Content.
              </Typography>
              <Typography variant="h5" gutterBottom>
                6. Acceptable Use
              </Typography>
              <Typography variant="body1" paragraph>
              Your use of the Site is subject to Shopik's Acceptable Use Policy. Shopik is not responsible or liable for any user Content or conduct on the Site. If you become aware of any misuse of the Site, please report such misuse immediately to Shopik at <a href='mailto:hello@shopik.io'>hello@shopik.io</a>.
              </Typography>
              <Typography variant="h5" gutterBottom>
                7. Compliance with Laws
              </Typography>
              <Typography variant="body1" paragraph>
              You represent that, in agreeing to, and performing under, these Terms, you are not violating, and will not violate, any governmental laws, rules, regulations, or orders that are applicable to your use of the Site (“Applicable Laws”). Without limiting the foregoing, you represent that, in connection with your performance under these Terms, you shall: (a) comply with Applicable Laws relating to anti-bribery and anti-corruption, which may include the US Foreign Corrupt Practices Act of 1977 and the UK Bribery Act 2010; (b) comply with Applicable Laws administered by the U.S. Commerce Bureau of Industry and Security, U.S. Treasury Office of Foreign Assets Control or other governmental entity imposing export controls and trade sanctions (“Export Laws”), including designating countries, entities, and persons (“Sanctions Targets”); and (c) not directly or indirectly export, re-export or otherwise deliver any Shopik software, content, or services to a Sanctions Target, or broker, finance, or otherwise facilitate any transaction in violation of any Export Laws. You represent that you are not a Sanctions Target or prohibited from receiving Shopik software, content, or services pursuant to these Terms under Applicable Laws, including Export Laws.
              </Typography>
              <Typography variant="h5" gutterBottom>
                8. Global Availability
              </Typography>
              <Typography variant="body1" paragraph>
              hopik controls the Site from its offices. If you use this website from other locations, you are responsible for compliance with local Applicable Laws. Shopik makes no representation that the products and services referenced herein are appropriate, or available, worldwide.
              </Typography>
              <Typography variant="h5" gutterBottom>
                9. Indemnity
              </Typography>
              <Typography variant="body1" paragraph>
              You agree to defend, indemnify, and hold harmless Shopik, its affiliates, and their respective employees, contractors, agents, officers, and directors from and against any and all claims, damages, obligations, losses, liabilities, costs, debt, or expenses (including without limitation attorneys' fees) arising out of or related to any claim, suit, action, or proceeding by a third party arising out of or relating to your use of the Site, breach of these Terms (including any Shopik policy referenced in these Terms), violation of law, or any Content that you post, upload, or cause to interface with the Site, or otherwise transfer, process, use, or store in connection with the Site.
              </Typography>
              <Typography variant="h5" gutterBottom>
                10. Disclaimers
              </Typography>
              <Typography variant="body1" paragraph>
              THE SITE AND ALL CONTENT AND OTHER ITEMS MADE AVAILABLE BY SHOPIK ARE PROVIDED ON AN “AS IS” AND “AS AVAILABLE” BASIS WITHOUT WARRANTIES OF ANY KIND, WHETHER EXPRESS, IMPLIED, STATUTORY, OR OTHERWISE, INCLUDING ANY IMPLIED WARRANTY OF TITLE, MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, OR NON-INFRINGEMENT, TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW. SHOPIK ASSUMES NO LIABILITY OR RESPONSIBILITY FOR ANY (i) ERRORS, MISTAKES OR INACCURACIES OF DATA OR INFORMATION POSTED, DISPLAYED, PUBLISHED OR MADE AVAILABLE FOR DOWNLOAD OR USE ON THE SITE, (ii) PERSONAL INJURY OR PROPERTY DAMAGE, OF ANY NATURE WHATSOEVER, RESULTING FROM USE OF THE SITE, (iii) ANY INTERRUPTION OR CESSATION OF TRANSMISSION TO OR FROM THE SITE, OR (iv) THE DEFAMATORY, OFFENSIVE OR ILLEGAL CONDUCT OF ANY THIRD PARTY NOT UNDER SHOPIK'S CONTROL.
              </Typography>
              <Typography variant="h5" gutterBottom>
                11. Limitation of Liability
              </Typography>
              <Typography variant="body1" paragraph>
              TO THE FULLEST EXTENT PERMITTED BY APPLICABLE LAW: (a) IN NO EVENT SHALL SHOPIK, ITS AFFILIATES, OR THEIR RESPECTIVE EMPLOYEES, CONTRACTORS, AGENTS, OFFICERS OR DIRECTORS BE LIABLE FOR ANY INDIRECT, PUNITIVE, INCIDENTAL, SPECIAL, CONSEQUENTIAL OR EXEMPLARY DAMAGES, INCLUDING WITHOUT LIMITATION DAMAGES FOR BUSINESS INTERRUPTION, LOSS OF PROFITS, GOODWILL, USE, DATA OR OTHER INTANGIBLE LOSSES ARISING OUT OF OR RELATING TO THE SITE; AND (b) IN NO EVENT SHALL SHOPIK'S CUMULATIVE AND AGGREGATE LIABILITY UNDER THESE TERMS EXCEED TWO HUNDRED U.S. DOLLARS. THE EXCLUSIONS AND LIMITATIONS IN THIS SECTION APPLY WHETHER THE ALLEGED LIABILITY IS BASED ON CONTRACT, TORT, NEGLIGENCE, STRICT LIABILITY OR ANY OTHER BASIS, EVEN IF YOU HAVE BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGE.
              </Typography>
              <Typography variant="h5" gutterBottom>
                12. Responsibility for End Users
              </Typography>
              <Typography variant="body1" paragraph>
              You are responsible for violations of these Terms by anyone using the Site with your permission or using your account on an unauthorized basis. Your use of the Site to assist another person in an activity that would violate these Terms if performed by you is a violation of these Terms. These Terms apply to anyone accessing or using the Site; however, each provision in these Terms shall be interpreted to include, and apply to, any action directly or indirectly taken, authorized, facilitated, promoted, encouraged, or permitted by a user of the Site, even if such person did not themselves violate the provision.
              </Typography>
              <Typography variant="h5" gutterBottom>
                13. Employment Opportunities
              </Typography>
              <Typography variant="body1" paragraph>
              Shopik may, from time to time, post employment opportunities on the Site and/or invite users to submit resumes to Shopik. If you choose to submit your name, contact information, resume, and/or other personal information to Shopik in response to such employment listings, you are authorizing Shopik to use this information for all lawful and legitimate hiring, employment, and other business purposes. Shopik also reserves the right, at its discretion, to forward such information to Shopik's affiliates for their legitimate business purposes. Nothing in these Terms or contained on the Site will constitute a promise by Shopik to review any such information or to contact, interview, or employ any individual who submits such information.
              </Typography>
              <Typography variant="h5" gutterBottom>
              14. Digital Millennium Copyright Act
              </Typography>
              <Typography variant="body1" paragraph>
              The Digital Millennium Copyright Act of 1998 (“DMCA”) provides recourse for copyright owners who believe that material appearing on the Internet infringes their rights under U.S. copyright law. If you believe that any material residing on or linked to from the Site infringes your copyright, please send (or have your agent send) to Shopik's Copyright Agent a notification of claimed infringement with all of the following information...
To file a counter notification with Shopik, please provide the relevant information to Shopik's Copyright Agent. Shopik's Copyright Agent for notification of claimed infringement can be reached as follows: Copyright Agent, Shopik, Inc., Attn: Legal. Shopik's Copyright Agent for notification of claimed infringement can also be reached electronically at:<a href='mailto:hello@shopik.io'>hello@shopik.io</a>. Shopik reserves the right to terminate infringers' and suspected infringers' access to or use of the Site.
              </Typography>
              <Typography variant="h5" gutterBottom>
              15. U.S. Government Rights
              </Typography>
              <Typography variant="body1" paragraph>
              The Site is provided to the U.S. Government as “commercial items,” “commercial computer software,” “commercial computer software documentation,” and “technical data” with the same rights and restrictions generally applicable to the Site. If you are using the Site on behalf of the U.S. Government and these terms fail to meet the U.S. Government's needs or are inconsistent in any respect with federal law, you must immediately discontinue the use of the Site.
              </Typography>
              <Typography variant="h5" gutterBottom>
                16. Notice for California Residents
              </Typography>
              <Typography variant="body1" paragraph>
              Under California Civil Code Section 1789.3, California users are entitled to the following consumer rights notice: Shopik does not currently charge any fees for access and use of the Site. If you have a question or complaint regarding the Site, please contact Shopik by writing to Shopik, Inc., Attn: Legal, or by contacting Shopik at <a href='mailto:hello@shopik.io'>hello@shopik.io</a>. California residents may reach the Complaint Assistance Unit of the Division of Consumer Services of the California Department of Consumer Affairs by mail or by telephone.
              </Typography>
              <Typography variant="h5" gutterBottom>
                17. Termination and Monitoring
              </Typography>
              <Typography variant="body1" paragraph>
              If you violate these Terms, Shopik may suspend or terminate your use of the Site. Shopik's right to suspend or terminate your use of Site applies even if a breach is committed unintentionally or without your authorization. Shopik reserves the right, but does not assume the obligation, to investigate any violation of these Terms or misuse of the Site. Shopik may report any activity it suspects violates any law or regulation to appropriate law enforcement officials, regulators, or other appropriate third parties.
              </Typography>
              <Typography variant="h5" gutterBottom>
                18. Electronic Communications
              </Typography>
              <Typography variant="body1" paragraph>
              When you visit the Site or send emails to Shopik, you are communicating with Shopik electronically; and you consent to receive communications from Shopik electronically. You agree that all agreements, notices, disclosures, and other communications that Shopik provides to you electronically satisfy any legal requirement that such communications be in writing.

    Modifications - Shopik may modify these Terms at any time by posting a revised version on the Site. By accessing the Site, you agree to the latest version of these Terms.

Governing Law - This Agreement will be interpreted, construed, and enforced in all respects according to the relevant laws. Any legal action or proceeding arising under or relating to this Agreement shall be brought in the relevant courts, and the Parties expressly consent to personal jurisdiction and venue in those courts.

Miscellaneous - These Terms constitute the complete and exclusive statement of the agreement between the Parties and supersede all proposals, oral or written, and all other communications between the Parties relating to the subject matter of these Terms.

Contact - Please contact Shopik at <a href='mailto:hello@shopik.io'>hello@shopik.io</a> with any questions regarding these Terms.
              </Typography>

              <Divider sx={{ marginY: 3 }} />
 {/* Privacy Policy Section */}
 <Typography variant="h4" gutterBottom>
              Privacy Policy
            </Typography>
            <Typography variant="body1" paragraph>
              Your trust is essential to us, and we aim to ensure that your personal information is protected. This Privacy Policy explains how we collect and use your personal information in relation to Shopik products, services, events, and websites or applications that link to this Policy (together, the “Shopik Products”). It also describes your rights and how you can exercise them concerning your personal information.
            </Typography>

            <Typography variant="h5" gutterBottom>
              Personal Information We Collect
            </Typography>
            <Typography variant="body1" paragraph>
              We process information about you while providing the Shopik Products. Below, we outline the types of information we collect.
            </Typography>

            <Typography variant="h6" gutterBottom>
              Information You Give to Us
            </Typography>
            <Typography variant="body1" paragraph>
              - We collect information about you when you provide it to us directly.
            </Typography>

            <Typography variant="subtitle1" gutterBottom>
              Your Shopik account.
            </Typography>
            <Typography variant="body1" paragraph>
              - We gather data about you when you submit it through the Shopik Products, including when you create an account with us. This may include your name, email address, profile picture, company name, postal address, and phone number. Additionally, it may encompass your payment information when you purchase any of the Shopik Products.
            </Typography>

            <Typography variant="subtitle1" gutterBottom>
              Support.
            </Typography>
            <Typography variant="body1" paragraph>
              - We collect information you provide to us when you request support or otherwise communicate with us (such as via third-party social-media sites). This may include your name, email address, title, company, and any other details you provide in your request.
            </Typography>

            <Typography variant="subtitle1" gutterBottom>
              Other information submitted by you.
            </Typography>
            <Typography variant="body1" paragraph>
              - We gather information about you that you submit to us through the Shopik Products, such as when you participate in any interactive features of the Shopik Products or complete a form on one of our websites. This data might include your name, email address, and mailing address.
            </Typography>

            {/* Additional Information Sections */}
            <Typography variant="h6" gutterBottom>
              Information We Collect Automatically
            </Typography>
            <Typography variant="body1" paragraph>
              - We automatically gather data about you when you access or use the Shopik Products.
            </Typography>

            <Typography variant="subtitle1" gutterBottom>
              Your use of the Shopik Products.
            </Typography>
            <Typography variant="body1" paragraph>
              - We collect information about your session, including date, time, duration, the pages you viewed, and the page you navigated from.
            </Typography>

            <Typography variant="subtitle1" gutterBottom>
              Device information.
            </Typography>
            <Typography variant="body1" paragraph>
              - We gather details about your computer or device, such as the type of browser you use, your IP address, and your device's location.
            </Typography>

            <Typography variant="subtitle1" gutterBottom>
              Cookies and similar technologies.
            </Typography>
            <Typography variant="body1" paragraph>
              - We utilize cookies and similar technologies (like web beacons and pixels) to collect data about your interactions with the Shopik Products. Please see our Cookie Policy for more details on how we use cookies and related technologies.
            </Typography>

            {/* How We Use and Share Personal Information */}
            <Typography variant="h5" gutterBottom>
              How We Use Personal Information
            </Typography>
            <Typography variant="body1" paragraph>
              We use your information for various purposes:
            </Typography>
            <Typography variant="body1" paragraph>
              - To provide the Shopik Products.<br />
              - For research and improvement of Shopik Products.<br />
              - To communicate with you.<br />
              - For security.<br />
              - To market and promote Shopik Products.<br />
              - To comply with legal obligations.<br />
              - With your consent.
            </Typography>

            <Divider sx={{ marginY: 3 }} />
{/* Cookie Policy Section */}
<Typography variant="h4" gutterBottom>
              Cookie Policy
            </Typography>
            <Typography variant="subtitle1" color="textSecondary" gutterBottom>
              Current as of {currentDate}.
            </Typography>

            <Typography variant="body1" paragraph>
              This Cookie Policy (this “Policy”) describes how Shopik, Inc. (“Shopik,” “we,” “us,” or “our”) uses cookies and similar technologies (collectively, “Cookies”) on websites that link to this Policy, (collectively, the “Sites”), and how you can manage the cookie settings in your browser.
            </Typography>

            <Typography variant="h5" gutterBottom>
              What is a Cookie?
            </Typography>
            <Typography variant="body1" paragraph>
              A cookie is a small piece of data that is stored on your device when you visit a website. You can think of cookies as providing a 'memory' for a website in order to make the website function or work more efficiently by recognizing users and their activity over time. For instance, a cookie may be used to record where you have paused a video so that you can resume playing it from the same spot later on. Some Cookies are required for technical reasons in order for the Sites to operate, and we refer to these as “essential” or “strictly necessary” Cookies. Other Cookies enable us to track and target the interests of our users to enhance the experience on our Sites.
            </Typography>

            <Typography variant="body1" paragraph>
              Cookies set by Shopik are called “first-party cookies”. Cookies set by parties other than Shopik are called “third-party cookies”. Third-party cookies enable third-party features or functionality to be provided on or through the Sites.
            </Typography>

            <Typography variant="h5" gutterBottom>
              How Shopik Uses Cookies
            </Typography>
            <Typography variant="body1" paragraph>
              Shopik uses Cookies to help us understand the profile of our visitors, recognize when you are signed in to your account, and provide you with a better user experience. We may also use Cookies to analyze and track data, determine the popularity of certain content, deliver advertising and content targeted to your interests on our Sites, and better understand your online activity.
            </Typography>

            <Typography variant="h5" gutterBottom>
              Third-Party Use of Cookies
            </Typography>
            <Typography variant="body1" paragraph>
              Some content or applications on the Sites are served by third-parties that may use Cookies to collect information about you when you use the Sites. The information they collect may be associated with your personal information or they may collect information about your online activities over time and across different websites and other online services.
            </Typography>

            <Typography variant="body1" paragraph>
              We do not control third-party cookies or how they may be used. If you have any questions about an advertisement or other targeted content, you should contact the responsible provider directly.
            </Typography>

            <Typography variant="h5" gutterBottom>
              What are your Choices Regarding Cookies?
            </Typography>
            <Typography variant="body1" paragraph>
              Most web browsers are set to accept Cookies by default. You can configure your browser to remove or reject Cookies as outlined below. By clearing the browsing history of your browser, you can delete Cookies from all websites you have visited on your device. Please note that if you choose to remove or reject Cookies, this could affect the availability and functionality of some of our services.
            </Typography>

            <Typography variant="h5" gutterBottom>
              Where can you find more information about cookies?
            </Typography>
            <Typography variant="body1" paragraph>
              If you want to learn more about cookies, or how to control, disable or delete them, please visit <a href="http://www.aboutcookies.org" target="_blank" rel="noopener noreferrer">http://www.aboutcookies.org</a> for detailed guidance.
            </Typography>

            <Typography variant="body1" paragraph>
              You can learn about opting out of third-party targeting cookies on the Digital Advertising Alliance's consumer choice page <a href="https://youradchoices.com/" target="_blank" rel="noopener noreferrer">here</a>, the Network Advertising Initiative's consumer choice page <a href="https://optout.networkadvertising.org/" target="_blank" rel="noopener noreferrer">here</a>, or the European Interactive Digital Advertising Alliance consumer choice page <a href="https://www.youronlinechoices.com/" target="_blank" rel="noopener noreferrer">here</a>.
            </Typography>

            <Typography variant="h5" gutterBottom>
              Changes to this Policy
            </Typography>
            <Typography variant="body1" paragraph>
              We may change this Policy from time to time. If we make changes, we will notify you by revising the date at the top of the Policy. We encourage you to review this Policy whenever you access the Sites or otherwise interact with us to stay informed about our use of Cookies.
            </Typography>

            <Typography variant="h5" gutterBottom>
              Where can I get further information?
            </Typography>
            <Typography variant="body1" paragraph>
              If you have questions or concerns about this Cookie Policy, please contact us at <a href="mailto:hello@shopik.io">hello@shopik.io</a>.
            </Typography>
            </Box>
          </CardContent>
        </Card>
      </Container>
      </Box>

{/* Footer at the bottom of the screen */}
<Box component="footer" sx={{ mt: 'auto', width: '100%' }}>
  <Footer /> {/* Your existing Footer component */}
</Box>
</Box>
</Box>
        );
      }
